import { AfterViewInit, Component, HostListener, NgZone } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderBookingComponent } from '@layout/header/header.component';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PermissionService } from './core/services/permission/permission.service';
import { ButtonModule } from 'primeng/button';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { SharedModule } from '@shared/shared.module';
import { SafeResourceUrl } from '@angular/platform-browser';
import { filter, Subject } from 'rxjs';
import { SoctripTranslationService } from './services/soctrip-translation/soctrip-translation.service';
import { MessageService } from 'primeng/api';
import { SoctripChatModule } from '@soctrip-common/chat';
import { UserInfo } from '@modules/users/models/user';
import { PixelAnalyticsService } from './core/services/pixel-analytics.service';
import { DeviceService } from './core/services/device.services';
import { showDownloadPopup, transform } from '@soctrip-common/route-transform-lib';
import { MulTranslationService } from './core/services/mul-translation/mul-translation.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    /* primeng */
    ButtonModule,
    /* @angular */
    RouterOutlet,
    /* @components */
    FooterComponent,
    HeaderBookingComponent,
    SharedModule,
    SoctripChatModule,
  ],
  providers: [MessageService],
  template: `
    <div *ngIf="!isLoading" class="relative">
      <app-header />
      <router-outlet></router-outlet>
      <app-popup-cta></app-popup-cta>
      <app-footer />
      <div *ngIf="isLogin && userInfo" class="soc-chat">
        <lib-chat-popup
          [userInfo]="userInfo"
          [baseUrl]="env.baseURL"
          [module]="env.MODULE"
          [isEnableChatBot]="true"
          [isDisableCloseDialogFromOutside]="true"
        ></lib-chat-popup>
      </div>
      <app-toast></app-toast>
    </div>
  `,
})
export class AppComponent implements AfterViewInit {
  //TODO: Replace with actual code
  loginForm = { password: '123456a@A', username: 'testaccount' };
  userRole: string[] = [];
  socialApp: string = environment.SOCIAL_APP_URL;
  socialAppConnectUrl: string = environment.SOCIAL_APP_URL + '/app-connect';
  socialAppSafeUrl: SafeResourceUrl;
  userInfo: UserInfo;
  isLoading: boolean = true;
  env = environment;
  private ngUnsubscribe = new Subject<void>();
  isLogin: boolean | null;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private permissionService: PermissionService,
    private translateService: TranslateService,
    private soctripTranslationService: SoctripTranslationService,
    private ngZone: NgZone, 
    private pixelAnalyticsService: PixelAnalyticsService,
    public deviceService: DeviceService,
      private mulTranslationService: MulTranslationService,
  ) {
    this.translateService.addLangs(environment.support_languages);
    // Open soctrip app when user uses mobile browser.
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
    //   if (event instanceof NavigationEnd && this.deviceService.isMobile()) {
    //     const currentUrl = window.location.href;
    //     transform(currentUrl, environment.baseURL).then((universalRoute: any) => {
    //       this.deviceService.tryOpenApp(universalRoute ?? environment.SOCIAL_APP_DEEPLINK);
    //     });
    //   }
    // });
    this.ngZone.runOutsideAngular(() => {
      this.pixelAnalyticsService.initialize();
    });
  }

  ngOnInit(): void {
    localStorage.setItem('ignore-secure', '');
    localStorage.setItem('unauthLang', environment.languages[0]);
    this.connectPMServer();
    this.getUserInfor();

    this.userService.getLoginStatus().subscribe((data) => {
      if (data !== null) {
        this.isLogin = data;
      }
    });
  }
  
  ngAfterViewInit(): void {
    if (this.deviceService.isMobile()) {
      let lang = environment.languages[0];
      this.translateService.addLangs(environment.languages);
      const browserLang = this.translateService.getBrowserLang() || lang;
      const selectedLang = this.mulTranslationService.getKeyLocalUserProfile('language')?.toLowerCase();
      if (selectedLang && environment.languages.includes(selectedLang)) {
        lang = selectedLang;
      } else if (!selectedLang && environment.languages.includes(browserLang)) {
        lang = localStorage.getItem('unauthLang') || (environment.languages.includes(browserLang) ? browserLang : lang);
      }
      const currentUrl = window.location.href;
      showDownloadPopup(lang, currentUrl, environment.baseURL);
    }
  }

  getUserInfor() {
    this.userService.getUserInfor().subscribe((user) => {
      if (user) this.userInfo = user;
    });
  }

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.socialAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    console.warn('event', event);
    if (window.location.hostname !== 'localhost') {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
      if (event.origin === this.socialApp) {
        if (
          event.data.type === 'authorized' ||
          event.data.type === 'unauthorized'
        ) {
          if (event.data.type === 'authorized' && event.data.token != null) {
            this.socialAppConnectUrl = '';
            this.tokenStorageService.saveToken(event.data.token.accessToken);
            const profile = JSON.parse(event.data.profile);
            localStorage.setItem(
              environment.USER_ROLE_KEY,
              JSON.stringify(['USER'])
            );
            const user = JSON.parse(
              localStorage.getItem(environment.USER_PROFILE) || '{}'
            );
            if(profile){
              localStorage.setItem(
                environment.USER_PROFILE,
                JSON.stringify(profile)
              );
            }
            if (profile?.id != user.id) {
              this.userService
                .getUserInfoById(profile?.id)
                .subscribe((data) => {
                  if (data) {
                    this.userService.setUserInfo(data);
                    localStorage.setItem(
                      environment.USER_PROFILE,
                      JSON.stringify(data)
                    );
                    this.userService.setLoginStatus(true);
                  }
                });
            }
            this.userService.setLoginStatus(true);
            localStorage.setItem(
              'auth_status',
              JSON.stringify({ isLoggedIn: true })
            );
            localStorage.removeItem('unauthLang');
            this.isLoading = false;
          } else {
            if (
              event.data.type === 'unauthorized' ||
              event.data.token === null
            ) {
              this.isLoading = false;
              localStorage.setItem('unauthLang', event.data.language);
              localStorage.setItem(
                'currency-conversation-data',
                event.data.currency
              );
              this.translateService.setDefaultLang(event.data.language);
              this.soctripTranslationService.setLanguage(event.data.language);
              localStorage.removeItem(environment.AUTH_STATUS);
              localStorage.removeItem(environment.TOKEN_KEY);
              localStorage.removeItem(environment.USER_PROFILE);
              localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
              localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
              localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
              localStorage.removeItem(environment.USER_ROLE_KEY);
              this.userService.setUserProfile(null);
              this.userService.setUserInfo(null);
              this.userService.setLoginStatus(false);
              // window.location.href = environment.SOCIAL_APP_URL + '/login';
            }
          }
        }
      }
    } else {
      this.isLoading = false;
    }
  }

  login() {
    this.authService.login(this.loginForm).subscribe((res: any) => {
      this.tokenStorageService.saveToken(res.accessToken);
      this.tokenStorageService.saveRefreshToken(res.refreshToken);
      this.tokenStorageService.saveAccessTokenExpiry(
        //TODO: Replace with actual code
        // res.access_token_expiry_date
        '2023-07-17 10:14:55'
      );
      this.tokenStorageService.saveRefreshTokenExpiry(
        //TODO: Replace with actual code
        // res.refresh_token_expiry_date
        '2023-07-30 17:50:55'
      );

      //TODO: Replace with actual code
      // userID (temporary)
      const userId = '123456';
      this.userService.getUserInfo(userId).subscribe((res: any) => {
        //TODO: Replace with actual code
        this.userRole = res;
        this.userService.saveUserInfoToLocalStorage(this.userRole);
      });
      this.permissionService.userRoles.next(this.userRole);
      this.router.navigate(['/users']);
    });
  }

  logout() {
    this.tokenStorageService.clearTokenLocalStorage();
    this.userService.clearUserLocalStorage();
    this.permissionService.userRoles.next(['']);

    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  //TODO: Replace with actual code
  getUserInfo() {
    this.authService.getUserInfo().subscribe();
    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  test(a: number, b: number): number {
    return a + b;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
